import React, { Component } from 'react'
import '../styles/_quote.scss';
import loading from '../assets/images/loading.gif';

const loadingCss = {
  position: "absolute",
  left: "0",
  right: "0",
  top: "40%",
  textAlign: "center"
};

class LoadingProcess extends Component {
  render() {
    return (
      <div className="loading-process" style={loadingCss}>
        <img src={loading} alt="loading..." title="loading"/>
      </div>

    )
  }
}
export default LoadingProcess;