import React from 'react'
import { NavLink as Link } from "react-router-dom";
import '../styles/_menubar.scss';
import { constURLs } from '../helper/constants';
// import Search from './search';

export default function Menubar() {

  const closeMenu = () => {
    var element = document.getElementById("navbar-top");
    element.classList.remove("has-active-menu");
    setTimeout(function () {
      var ds = document.getElementsByClassName("nav-slider");
      ds[0].classList.remove("toggling");
    }, 500);
  }

  const openMenu = () => {
    var element = document.getElementsByClassName("navbar-top");
    element[0].classList.add("has-active-menu");
    element = document.getElementsByClassName("nav-slider");
    element[0].classList.add("toggling");
  }

  const toggleItem = (e) => {
    if (e.currentTarget.classList && !e.currentTarget.classList.contains("active-item")) {
      var ds = document.getElementsByClassName("nav-item");
      var i = 0;
      while (i < ds.length) {
        ds[i].classList.remove("active-item");
        i++;
      }
      e.currentTarget.classList.add("active-item");
    }
    else {
      e.currentTarget.classList.remove("active-item");
    }
  }

  const mouseEnter = (e) => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false) {
      e.currentTarget.classList.add("active-item");
    }
  }

  const mouseLeave = (e) => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false) {
      e.currentTarget.classList.remove("active-item");
    }
  }

  return (

    <div className="navbar-top" id="navbar-top">
      <nav className="navbar-dark">
        <div className="navbar-toggler" onClick={() => openMenu()}>
          &#9776;
        </div>
        <a className="navbar-brand-item" href="/"><span className='yog'>Yog</span><span className='lica'>Lica</span></a>

        <div className="nav-slider">
          <div className="close-menu" onClick={() => closeMenu()}>x</div>
          <ul className="nav menu-container">
            {/* <Search /> */}
            <li className="nav-item active">
              <Link to="/" className="nav-link" onClick={() => closeMenu()}>Home</Link>
            </li>
            <li className="nav-item" onClick={toggleItem} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
              <div className="nav-link featuresLink">Spirituality<span className="caret-icon"></span></div>
              <div className="features-inner-container">

                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="sub-menu-head">Quotes</div>
                      <ul className="sub-menu-lists">
                        <li><Link to={constURLs.bg1} onClick={() => closeMenu()}>Bhagavad Gita Quote</Link></li>
                        <li><Link to={constURLs.OshoLife} onClick={() => closeMenu()}>Osho Life Quote</Link></li>
                        <li><Link to={constURLs.OshoDeath} onClick={() => closeMenu()}>Osho Death Quote</Link></li>
                        <li><Link to={constURLs.bg2Success} onClick={() => closeMenu()}>Bhagavad Gita Success Quotes</Link></li>
                        <li><Link to={constURLs.saiQuotes} onClick={() => closeMenu()}>Shirdi Sai Quote</Link></li>
                        <li><Link to={constURLs.bibleFactsUrl} onClick={() => closeMenu()}>Bible Facts</Link></li>
                        <li><Link to={constURLs.shivaFactsUrl} onClick={() => closeMenu()}>Lord Shiva Facts</Link></li>
                      </ul>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="sub-menu-head">Yoga and Meditation</div>
                      <ul className="sub-menu-lists">
                        <li><Link to={constURLs.mantraJapa} onClick={() => closeMenu()}>Mantra Japa</Link></li>
                        <li><Link to={constURLs.kundaliniYoga} onClick={() => closeMenu()}>Awaken Kundalini Chakra</Link></li>
                        <li><Link to={constURLs.pranayama} onClick={() => closeMenu()}>Pranayama Breathing</Link></li>
                        <li><Link to={constURLs.karmaYoga} onClick={() => closeMenu()}>Karma Yoga</Link></li>
                        <li><Link to={constURLs.lifePathNumberUrl} onClick={() => closeMenu()}>Numerology Prediction</Link></li>
                        <li><Link to={constURLs.meditationBenefitsUrl} onClick={() => closeMenu()}>Japa Meditation</Link></li>
                      </ul>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="sub-menu-head">Spiritual Living</div>
                      <ul className="sub-menu-lists">
                      <li><Link to={constURLs.vegetarianDiet} onClick={() => closeMenu()}>Vegetarian Diet</Link></li>
                        <li><Link to={constURLs.celibacy} onClick={() => closeMenu()}>Bramacharya or Celibacy</Link></li>
                        <li><Link to={constURLs.ekadeshi} onClick={() => closeMenu()}>Ekadeshi Vrath</Link></li>
                        <li><Link to={constURLs.howToGetSpiritual} onClick={() => closeMenu()}>Religion and Spirituality</Link></li>
                        <li><Link to={constURLs.cowWorship} onClick={() => closeMenu()}>Why do hindus worship cows</Link></li>
                        <li><Link to={constURLs.brahmamuhurtaUrl} onClick={() => closeMenu()}>Brahma Muhurta Time</Link></li>
                        <li><Link to={constURLs.krishnaMgmLessonUrl} onClick={() => closeMenu()}>Krishna Life Lessons</Link></li>
                        <li><Link to={constURLs.lifePurposeUrl} onClick={() => closeMenu()}>Purpose of Life - Gita</Link></li>
                      </ul>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-3">
                      <div className="sub-menu-head">Spiritual Places</div>
                      <ul className="sub-menu-lists">
                        <li><Link to={constURLs.rameshwaramUrl} onClick={() => closeMenu()}>Rameshwaram Jyotirlinga</Link></li>
                        <li><Link to={constURLs.nidhvanUrl} onClick={() => closeMenu()}>Nidhivan Vrindavan</Link></li>
                        <li><Link to={constURLs.kedarnathYatra} onClick={() => closeMenu()}>Kedarnath Yatra</Link></li>
                        <li><Link to={constURLs.shaniShingnapur} onClick={() => closeMenu()}>Shani signapur Yatra</Link></li>
                        <li><Link to={constURLs.varanasi} onClick={() => closeMenu()}>Varnasi Yatra</Link></li>
                        <li><Link to={constURLs.rishikesh} onClick={() => closeMenu()}>Rishikesh Yatra</Link></li>
                        <li><Link to={constURLs.jagannath} onClick={() => closeMenu()}>Puri Jagannath Yatra</Link></li>
                        <li><Link to={constURLs.ramJanamBhoomiUrl} onClick={() => closeMenu()}>Ayodhya Ram Mandir</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link to={constURLs.aboutUs} className="nav-link">About Us</Link>
            </li>
            <li className="nav-item">
              <Link to={constURLs.contactUs} className="nav-link">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="nav-mask" onClick={() => openMenu()}></div>
      </nav>
    </div>
  )
}