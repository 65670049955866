import React, { useEffect } from "react";
import "./index.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import Menubar from './components/menubar';
import Footer from './components/footer';
import './styles/_app.scss';
import LoadingProcess from "./components/loading-process";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { constURLs } from './helper/constants';

const LazyHome =  React.lazy(() => import('./containers/home'));
const LazyAbout = React.lazy(() => import('./containers/about-us'));
const LazyContact =  React.lazy(() => import('./containers/contact-us'));
const LazyHindu =  React.lazy(() => import('./containers/religion/hindu'));
const LazyBuddism =  React.lazy(() => import('./containers/religion/buddism'));
const LazyYogaAndMeditation =  React.lazy(() => import('./containers/yoga-and-meditation'));
const LazyPrayanama =  React.lazy(() => import('./containers/yoga-and-meditation/prayanama'));
const LazyKarmaYoga = React.lazy(() => import('./containers/yoga-and-meditation/karma-yoga'));
const LazyMantraJapa =  React.lazy(() => import('./containers/yoga-and-meditation/how-to-practice-mantra-japa-meditation'));
const LazyCelibacy = React.lazy(() => import('./containers/celibacy'));
const LazySpiritualAwaken =  React.lazy(() => import('./containers/spiritual-awakening'));
const LazyRegSpir =  React.lazy(() => import('./containers/religion-and-spirituality'));
const LazyVegeDiet =  React.lazy(() => import('./containers/religion-and-spirituality/vegetarian-diet'));
const LazyEkadasi =  React.lazy(() => import('./containers/religion-and-spirituality/ekadasi-fasting'));
const LazyCow = React.lazy(() => import('./containers/religion-and-spirituality/hindu-worship-cow'));
const LazySpiritual =  React.lazy(() => import('./containers/religion-and-spirituality/how-to-get-spiritual'));
const LazyReligiousBeliefs =  React.lazy(() => import('./containers/religious-beliefs'));
const LazyOm = React.lazy(() => import('./containers/religious-beliefs/om'));
const LazyNonAttachment = React.lazy(() => import('./containers/religious-beliefs/non-attachment'));
const LazyConsideredDead =  React.lazy(() => import('./containers/religious-beliefs/types-of-people-who-are-considered-dead'));
const LazyTravel =  React.lazy(() => import('./containers/travel-destination'));
const LazyVaranasi =  React.lazy(() => import('./containers/travel-destination/varanasi-yatra'));
const LazyKedarnath =  React.lazy(() => import('./containers/travel-destination/kedarnath-yatra'));
const LazyRishikesh = React.lazy(() => import('./containers/travel-destination/rishikesh-yatra'));
const LazyRameshwaram =  React.lazy(() => import('./containers/travel-destination/rameshwaram-jyotirlinga-temple'));
const LazyShaniYatra = React.lazy(() => import('./containers/travel-destination/shani-shingnapur-yatra'));
const LazyJagannath =  React.lazy(() => import('./containers/travel-destination/krishna-heart-alive-in-puri-jagannath-temple-miracle'));
const LazyChakra =  React.lazy(() => import('./containers/yoga-and-meditation/how-to-awaken-kundalini-chakra'));
const LazyPrivacy =  React.lazy(() => import('./containers/privacy'));
const LazyTnC =  React.lazy(() => import('./containers/termsandconditions'));
const LazyDisclaimer =  React.lazy(() => import('./containers/disclaimer'));
const LazypageNotFound404 = React.lazy(() => import('./containers/404'));
const LazyMahavatarBabaji =  React.lazy(() => import('./containers/guru/mahavatar-babaji'));
const LazyRamanaMaharshi = React.lazy(() => import('./containers/guru/biography-of-ramana-maharshi'));
const LazySanyasAshram = React.lazy(() => import('./containers/bhagavad-gita/leaving-family-to-become-a-sanyasi'));
const LazyAshtanga = React.lazy(() => import('./containers/religion-and-spirituality/patanjali-ashtanga-yoga-step'));
const LazyShivPurana10Things = React.lazy(() => import('./containers//shiva/10-Things-Of-Shiva-Purana-Useful-In-Life'));
const LazyQuotes = React.lazy(() => import('./containers/quotes'));
const Lazybg1 = React.lazy(() => import('./containers/quotes/top-15-bhagavad-gita-inspirational-quotes'));
const LazyOshoLife = React.lazy(() => import('./containers/quotes/top-15-osho-inspirational-quotes-on-life'));
const LazyOshoDeath = React.lazy(() => import('./containers/quotes/top-15-osho-inspirational-quotes-on-life-and-death'));
const Lazybg2Success = React.lazy(() => import('./containers/quotes/top-7-bhagavad-gita-quotes-on-success'));
const LazySaiBabaQuote = React.lazy(() => import('./containers/quotes/sai-baba-quotes'));
const LazySatyaSaiBabaQuote = React.lazy(() => import('./containers/quotes/sathya-sai-baba-quotes-in-english'));
const LazyVivekanandaQuote = React.lazy(() => import('./containers/quotes/vivekananda-quotes-life'));
const LazySalvation = React.lazy(() => import('./containers/salvation'));
const LazyUjjainDarshan = React.lazy(() => import('./containers/travel-destination/ujjain-darshan-tourism'));
const LazyBrahmamuhurta = React.lazy(() => import('./containers/religion-and-spirituality/brahmamuhurtha'));
const LazyKrishnaLesson = React.lazy(() => import('./containers/bhagavad-gita/krishna-management-life-lessons'));
const LazySaibabaMiracles = React.lazy(() => import('./containers/religious-beliefs/miracle-of-shirdi-saibaba'));
const LazyBenefitsMeditation = React.lazy(() => import('./containers/yoga-and-meditation/benefits-of-meditation'));
const LazyBibleFacts = React.lazy(() => import('./containers/christian/amazing-bible-facts-statistics'));
const LazyMysteryOfChrist = React.lazy(() => import('./containers/christian/mystery-of-jesus-christ'));
const LazyShivaFacts = React.lazy(() => import('./containers/religion-and-spirituality/shiva-facts'));
const LazyJyotirLinga = React.lazy(() => import('./containers/religion-and-spirituality/jyotirlinga-of-india'));
const LazyLifePathNumber = React.lazy(() => import('./containers/numerology/life-path-number-numerology'));
const LazyLifePath_1 = React.lazy(() => import('./containers/numerology/life-path-number-1-meaning-numerology'));
const LazyLifePath_2 = React.lazy(() => import('./containers/numerology/life-path-number-2-meaning-numerology'));
const LazyLifePath_3 = React.lazy(() => import('./containers/numerology/life-path-number-3-meaning-numerology'));
const LazyLifePath_4 = React.lazy(() => import('./containers/numerology/life-path-number-4-meaning-numerology'));
const LazyLifePath_5 = React.lazy(() => import('./containers/numerology/life-path-number-5-meaning-numerology'));
const LazyLifePath_6 = React.lazy(() => import('./containers/numerology/life-path-number-6-meaning-numerology'));
const LazyLifePath_7 = React.lazy(() => import('./containers/numerology/life-path-number-7-meaning-numerology'));
const LazyLifePath_8 = React.lazy(() => import('./containers/numerology/life-path-number-8-meaning-numerology'));
const LazyLifePath_9 = React.lazy(() => import('./containers/numerology/life-path-number-9-meaning-numerology'));
const LazyBuddhaPurnima = React.lazy(() => import('./containers/religion-and-spirituality/gautam-buddha-purnima-vesak'));
const LazyKrishnaPurnaAvatar = React.lazy(() => import('./containers/bhagavad-gita/shri-krishna-purna-avatar'));
const LazyNidhvanVrindavan = React.lazy(() => import('./containers/bhagavad-gita/nidhivan-in-vrindavan-mystery'));
const LazyKailashMystery = React.lazy(() => import('./containers/shiva/kailash-mountain-mystery'));
const LazyGitaSummary = React.lazy(() => import('./containers/bhagavad-gita/bhagavad-gita-summary'));
const LazyRadhaAshtami = React.lazy(() => import('./containers/bhagavad-gita/radha-ashtami'));
const LazyStressReduce = React.lazy(() => import('./containers/health-and-wellbeing/spiritual-steps-to-reduce-stress'));
const LazyRamMandir = React.lazy(() => import('./containers/religion-and-spirituality/sree-ram-janmabhoomi-mandir-ayodhya'));
const LazyLifePurpose = React.lazy(() => import('./containers/bhagavad-gita/purpose-of-life-bhagavad-gita'));

function GetPageurl() {
  redirecturl();
  let location = useLocation();
  useEffect(() => {
    const domainName = "https://yoglica.com";
    const path = domainName + (window && window.location.pathname) || "/404";
    window.yoglicapath = path;
	}, [location]);
}
//redirect client side not recommended testing code for 1 month start date: 27-7-2022
//check if ekadashi is listed with slash
function redirecturl() {
  const path = window.location.pathname;
  if (!path.endsWith("/")) {
    window.location.href = path + "/";
    }
}

export default function App() {
  let pageName = "";
  if (typeof window != 'undefined') {
    if(window.location.pathname === '/') {
      pageName = "homepage"
    }
  }
  GetPageurl();
  return(
    <React.Suspense fallback={<LoadingProcess />}>
      <div>
        <Menubar />
      </div>
      <div className={"app-top-container " + pageName}>
      
      <div className="content app-container">
      <Routes>
          
          <Route exact path="/" element={<LazyHome />} />
          <Route exact path={constURLs.aboutUs} element={<LazyAbout />} />
          <Route exact path={constURLs.contactUs} element={<LazyContact />} />
          <Route exact path={constURLs.hindu} element={<LazyHindu />} />
          <Route exact path={constURLs.celibacy} element={<LazyCelibacy />} />
          <Route exact path={constURLs.spiritualAwakenUrl} element={<LazySpiritualAwaken />} />
          <Route exact path={constURLs.buddism} element={<LazyBuddism />} />
          <Route exact path={constURLs.yogaAndMeditation} element={<LazyYogaAndMeditation />} />
          <Route exact path={constURLs.pranayama} element={<LazyPrayanama/>} />
          <Route exact path={constURLs.kundaliniYoga} element={<LazyChakra />} />
          <Route exact path={constURLs.karmaYoga} element={<LazyKarmaYoga/>} />
          <Route exact path={constURLs.religionAndSpirituality} element={<LazyRegSpir />} />
          <Route exact path={constURLs.vegetarianDiet} element={<LazyVegeDiet />} />
          <Route exact path={constURLs.ekadeshi} element={<LazyEkadasi />} />
          <Route exact path={constURLs.howToGetSpiritual} element={<LazySpiritual />} />
          <Route exact path={constURLs.cowWorship} element={<LazyCow />} />
          <Route exact path={constURLs.religiousBeliefs} element={<LazyReligiousBeliefs />} />
          <Route exact path={constURLs.om} element={<LazyOm />} />
          <Route exact path={constURLs.mantraJapa} element={<LazyMantraJapa />} />
          <Route exact path={constURLs.attachment} element={<LazyNonAttachment />} />
          <Route exact path={constURLs.consideredDead} element={<LazyConsideredDead />} />

          <Route exact path={constURLs.travelDestinations} element={<LazyTravel />} />
          <Route exact path={constURLs.varanasi} element={<LazyVaranasi />} />
          <Route exact path={constURLs.kedarnathYatra} element={<LazyKedarnath />} />
          <Route exact path={constURLs.rishikesh} element={<LazyRishikesh />} />
          <Route exact path={constURLs.shaniShingnapur} element={<LazyShaniYatra />} />
          <Route exact path={constURLs.jagannath} element={<LazyJagannath />} />
          <Route exact path={constURLs.ujjainDarshanUrl} element={<LazyUjjainDarshan />} />
          <Route exact path={constURLs.rameshwaramUrl} element={<LazyRameshwaram />} />
            
          <Route exact path={constURLs.mahaAvatarBabaji} element={<LazyMahavatarBabaji />} />
          <Route exact path={constURLs.ramanaMaharshi} element={<LazyRamanaMaharshi />} />
          <Route exact path={constURLs.sanyasAshram} element={<LazySanyasAshram />} />
          <Route exact path={constURLs.privacy} element={<LazyPrivacy />} />
          <Route exact path={constURLs.tnc} element={<LazyTnC />} />
          <Route exact path={constURLs.disclaimerUrl} element={<LazyDisclaimer />} />
          <Route exact path={constURLs.ashtanga} element={<LazyAshtanga />} />
          <Route exact path={constURLs.shivPurana10Things} element={<LazyShivPurana10Things />} />
          <Route exact path={constURLs.quotes} element={<LazyQuotes />} />
          <Route exact path={constURLs.bg1} element={<Lazybg1 />} />
          <Route exact path={constURLs.OshoLife} element={<LazyOshoLife />} />
          <Route exact path={constURLs.OshoDeath} element={<LazyOshoDeath />} />
          <Route exact path={constURLs.bg2Success} element={<Lazybg2Success />} />
          <Route exact path={constURLs.saiQuotes} element={<LazySaiBabaQuote />} />
          <Route exact path={constURLs.salvationUrl} element={<LazySalvation />} />
          <Route exact path={constURLs.brahmamuhurtaUrl} element={<LazyBrahmamuhurta />} />
          <Route exact path={constURLs.krishnaMgmLessonUrl} element={<LazyKrishnaLesson />} />
          <Route exact path={constURLs.saibabaMiracleUrl} element={<LazySaibabaMiracles />} />
          <Route exact path={constURLs.meditationBenefitsUrl} element={<LazyBenefitsMeditation />} />
          <Route exact path={constURLs.bibleFactsUrl} element={<LazyBibleFacts />} />
          <Route exact path={constURLs.shivaFactsUrl} element={<LazyShivaFacts />} />
          <Route exact path={constURLs.jyotirlingaUrl} element={<LazyJyotirLinga />} />
          <Route exact path={constURLs.lifePathNumberUrl} element={<LazyLifePathNumber />} />
          <Route exact path={constURLs.lifePath_1_Url} element={<LazyLifePath_1 />} />
          <Route exact path={constURLs.lifePath_2_Url} element={<LazyLifePath_2 />} />
          <Route exact path={constURLs.lifePath_3_Url} element={<LazyLifePath_3 />} />
          <Route exact path={constURLs.lifePath_4_Url} element={<LazyLifePath_4 />} />
          <Route exact path={constURLs.lifePath_5_Url} element={<LazyLifePath_5 />} />
          <Route exact path={constURLs.lifePath_6_Url} element={<LazyLifePath_6 />} />
          <Route exact path={constURLs.lifePath_7_Url} element={<LazyLifePath_7 />} />
          <Route exact path={constURLs.lifePath_8_Url} element={<LazyLifePath_8 />} />
          <Route exact path={constURLs.lifePath_9_Url} element={<LazyLifePath_9 />} />
          <Route exact path={constURLs.mysteryOfChristUrl} element={<LazyMysteryOfChrist />} />
          <Route exact path={constURLs.buddhaPurnima} element={<LazyBuddhaPurnima />} />
          <Route exact path={constURLs.krishnaPurnaAvatarUrl} element={<LazyKrishnaPurnaAvatar />} />
          <Route exact path={constURLs.nidhvanUrl} element={<LazyNidhvanVrindavan />} />
          <Route exact path={constURLs.kailashMysteryUrl} element={<LazyKailashMystery />} />
          {/* <Route exact path={constURLs.gitaSummaryUrl} element={<LazyGitaSummary />} /> */}
          <Route exact path={constURLs.satyaSaiQuotesUrl} element={<LazySatyaSaiBabaQuote />} />
          <Route exact path={constURLs.vivekanandaQuotesUrl} element={<LazyVivekanandaQuote />} />
          <Route exact path={constURLs.radhaAshtamiUrl} element={<LazyRadhaAshtami />} />
          <Route exact path={constURLs.stressReduceSpiritualUrl} element={<LazyStressReduce />} />
          <Route exact path={constURLs.ramJanamBhoomiUrl} element={<LazyRamMandir />} />
          <Route exact path={constURLs.lifePurposeUrl} element={<LazyLifePurpose />} />
          <Route path="*" element={<LazypageNotFound404 />} status={404} />
      </Routes>
      </div>
      <LazyLoadComponent>
          <Footer />
      </LazyLoadComponent>
      
      </div>
      </React.Suspense>
  );
}