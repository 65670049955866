//Full size Images 675*400px
import shivaFull from "../assets/images/fullsize-images/shiva.webp";
import attachmentFull from "../assets/images/fullsize-images/attachment.webp";
import varnasiFull from "../assets/images/fullsize-images/varanasi.webp";
import kundaliniFull from "../assets/images/fullsize-images/kundalini.webp";
import kedarnathFull from "../assets/images/fullsize-images/kedarnath.webp";
import pranayamaFull from "../assets/images/fullsize-images/pranayama.webp";
import rishikeshFull from "../assets/images/fullsize-images/rishikesh.webp";
import vegetarianDietFull from "../assets/images/fullsize-images/vegeterian-diet.webp";
import karmaYogaFull from "../assets/images/fullsize-images/karma-yoga.webp";
import gauMataFull from "../assets/images/fullsize-images/gau-mata.webp";
import shaniShingnapurFull from "../assets/images/fullsize-images/shani-shingnapur-yatra.webp";
import ekadasiFull from "../assets/images/fullsize-images/ekadasi-yatra.webp";
import radhaKrishnaFull from "../assets/images/fullsize-images/krishna-and-radha.webp";
import buddismFull from "../assets/images/fullsize-images/buddism.webp";
import kundaliniChakra from "../assets/images/fullsize-images/kundalini-chakra.webp";
import spiritualFull from "../assets/images/fullsize-images/spirituality.webp";
import jagannathFull from "../assets/images/fullsize-images/jagannath-temple-night-view.webp";
import jagannathTripletFull from "../assets/images/fullsize-images/jagannath-temple-wooden-idol-god.webp";
import mahaAvatarBabajiFull from "../assets/images/fullsize-images/maha-avatar-babaji.webp";
import babajiCaveFull from "../assets/images/fullsize-images/babaji-cave-in-himalaya.webp";
import bramacharyaFull from "../assets/images/fullsize-images/celibacy-or-bramacharya.webp";
import adiYogiShivaFull from "../assets/images/fullsize-images/adhiyogi-shiva-sadhguru-coimbatore-isha-yoga-full.webp";
import shivlingaFull from "../assets/images/fullsize-images/12-Jyotirlingas-shivlinga.webp";
import shivaTempleWorshipFull from "../assets/images/fullsize-images/shiva-temple-worship.webp";
import somnathFull from "../assets/images/fullsize-images/somnath-temple-outer-view-shiva.webp";

import bruntonRamanaFull from "../assets/images/fullsize-images/brunton-sitting-with-ramana-maharshi.webp";
import ramanaChildhoodFull from "../assets/images/fullsize-images/ramana-childhood.webp";
import ramanaMaharshiFull from "../assets/images/fullsize-images/ramana-maharshi.webp";
import ramcharitmanasFull from "../assets/images/fullsize-images/ramcharitmanas.webp";
import ashtangaFull from "../assets/images/fullsize-images/patanjali-ashtanga-yoga.webp";
import shivaArtFull from "../assets/images/fullsize-images/shiva-art-kerala.webp";
import rudrakshaMalaFull from "../assets/images/fullsize-images/japa-mala-rudraksha.webp";
import mantraJapaWithMalaFull from "../assets/images/fullsize-images/how-to-do-mantra-japa-with-mala.webp";
import hanumanFull from "../assets/images/fullsize-images/hanuman.webp";
import spiritualAwakenFull from "../assets/images/fullsize-images/spiritual-awakening.webp";
import spiritualSanyasiFull from "../assets/images/fullsize-images/What-exactly-is-spiritual-awakening.webp";
import girlDrinkingJuiceFull from "../assets/images/fullsize-images/how-long-does-it-last-spiritual-awakening.webp";
import salvationFull from "../assets/images/fullsize-images/bird-in-hand-salvation-moksha.webp";

import mahakalFull from "../assets/images/fullsize-images/mahakal-ujjain-temple.webp";
import banyanTreeFull from "../assets/images/fullsize-images/banyan-tree-mystic.webp";
import mahakumbhFull from "../assets/images/fullsize-images/mahakumbh-mela.webp";
import shakthiPeetFull from "../assets/images/fullsize-images/shakthipeeth-maa-shakthi-durga-ma.webp";
import sunrisefull from "../assets/images/fullsize-images/sunrise-light-happy-soul.webp";
import poojafull from "../assets/images/fullsize-images/daily-pooja-arthi.webp";
import saibabafull from "../assets/images/fullsize-images/sai-baba-shridi.webp";
import saibabaGarlandFull from "../assets/images/fullsize-images/shirdi-saibaba-flower-garland.webp";
import buddhaBodyFull from "../assets/images/fullsize-images/buddha-boy-meditation.webp";
import jesusBlessingDiscipleFull from "../assets/images/fullsize-images/jesus-blessing-painting.webp";
import jesusBibleFull from "../assets/images/fullsize-images/jesus-with-a-bible.webp";

import lifePathNumberFull from "../assets/images/fullsize-images/numerology/numerology-life-path-number.webp";
import lifePathMatrixFull from "../assets/images/fullsize-images/numerology/numerology-life-path-matrix.webp";
import frogMoneyEconomyFull from "../assets/images/fullsize-images/numerology/money-status-frog-economy.webp";
import lifePath1Full from "../assets/images/fullsize-images/numerology/life-path-number-1-meaning.webp";
import lifePath2Full from "../assets/images/fullsize-images/numerology/life-path-number-2-or-moolank-2.webp";
import lifePath3Full from "../assets/images/fullsize-images/numerology/life-path-number-3-or-moolank-3.webp";
import lifePath4Full from "../assets/images/fullsize-images/numerology/life-path-number-4-or-moolank-4.webp";
import lifePath5Full from "../assets/images/fullsize-images/numerology/life-path-number-5-or-moolank-5.webp";
import lifePath6Full from "../assets/images/fullsize-images/numerology/life-path-number-6-or-moolank-6.webp";
import lifePath7Full from "../assets/images/fullsize-images/numerology/life-path-number-7-or-moolank-7.webp";
import lifePath8Full from "../assets/images/fullsize-images/numerology/life-path-number-8-or-moolank-8.webp";
import lifePath9Full from "../assets/images/fullsize-images/numerology/life-path-number-9-or-moolank-9.webp";
import buddhaPurnimaFull from "../assets/images/fullsize-images/gautam-buddha-purnima-vesak.webp";

import santaClausFull from "../assets/images/fullsize-images/santa-claus-real.webp";
import bibleBookFull from "../assets/images/fullsize-images/bible-book-full.webp";
import radhaKrishnaImageFull from "../assets/images/fullsize-images/radha-krishna-image.webp";
import questionWhyHowFull from "../assets/images/fullsize-images/question-why-how-person.webp";
import maryJesusFull from "../assets/images/fullsize-images/mary-jesus-christ-kid.webp";
import december25Full from "../assets/images/fullsize-images/christmas-day-25-december.webp";

import ramanathswamiTempleFull from "../assets/images/fullsize-images/arulmigu ramanathaswamy temple.webp";
import pambanBridgeFull from "../assets/images/fullsize-images/rameshwaram pamban bridge images.webp";

import radhaLoveStoryFull from "../assets/images/fullsize-images/radha-love-story.webp";
import scriptureBhagavadGitaFull from "../assets/images/fullsize-images/scripture-bhagavad-gita.webp";
import radhaKrishnaPremKhaniFull from "../assets/images/fullsize-images/radha-krishna-prem-khani.webp";
import cottageLakefull from "../assets/images/fullsize-images/cottage-with-lake-and-hill-view.webp";
import kailashParvathfull from "../assets/images/fullsize-images/Kailash-Parvat-Mystery-Mountain.webp";
import kailashTimeTravelfull from "../assets/images/fullsize-images/kailash-time-faster.webp";
import kailashMountainfull from "../assets/images/fullsize-images/Kailash-mountain-close-view.webp";
import vivekanandaOldfull from "../assets/images/fullsize-images/swami-vivekananda-old-picture.webp";
import vivekanandaTemplefull from "../assets/images/fullsize-images/vivekananda-temple-kanniyakumari.webp";
import ramMandirFull from "../assets/images/fullsize-images/ram-mandir-ayodhya.webp";
import lordRamFull from "../assets/images/fullsize-images/lord-ram-devotional-image.webp";

//Mini Images 185*185px
import vegetarianMini from "../assets/images/mini-images/vegetarian-mini.webp";
import ekadasiMini from "../assets/images/mini-images/ekadasi-yatra-mini.webp";
import gauMataMini from "../assets/images/mini-images/gau-mata-mini.webp";
import hinduGodMini from "../assets/images/mini-images/hindu-god-mini.webp";
import spiritualMini from "../assets/images/mini-images/spirituality-mini.webp";
import jagannathMini from "../assets/images/mini-images/puri-jagannath-temple-mini.webp";
import ramcharitmanasMini from "../assets/images/mini-images/ramcharitmanas-mini.webp";
import colorFaceMini from "../assets/images/mini-images/color-face-mini.webp";
import ashtangaYogaMini from "../assets/images/mini-images/ashtanga-yoga-patanjali.webp";
import spiritualAwakeningMini from "../assets/images/mini-images/spiritual-awakening.webp";
import mokshaSalvationMini from "../assets/images/mini-images/salvation-or-moksha.webp";
import saibabaMini from "../assets/images/mini-images/saibaba-mini.webp";
import jesusBlessMini from "../assets/images/mini-images/blessing-jesus-mini.webp";
import adiYogiShivaMini from "../assets/images/mini-images/adhiyogi-shiva-sadhguru-coimbatore-isha-yoga-mini.webp";
import shivalingaMini from "../assets/images/mini-images/jyotirlinga-of-india-shivaling-mini.webp";
import numerologyMini from "../assets/images/mini-images/numerology-calculator-moolank-future-prediction-mini.webp";

//micro Images 150*150px
import attachmentMini from "../assets/images/micro-images/attachment-150px.webp";
import shivaMini from "../assets/images/micro-images/shiva-150px.webp";
import varanasiMini from "../assets/images/micro-images/varanasi-150px.webp";
import kundaliniMini from "../assets/images/micro-images/kundalini-150px.webp";
import kedarnathMini from "../assets/images/micro-images/kedarnath-150px.webp";
import rishkeshMini from "../assets/images/micro-images/rishikesh-150px.webp";
import pranayamaMini from "../assets/images/micro-images/pranayama-150px.webp";
import spiritualMeditationMini from "../assets/images/micro-images/spiritual-benefits-of-meditation.webp";
import babajiMini from "../assets/images/micro-images/maha-avatar-babaji-meditation-pose.webp";
import radheKrishnaMini from "../assets/images/micro-images/radhe-krishna-micro.webp";
import rudrakshaMalaMini from "../assets/images/micro-images/rudraksha-mala.webp";
import mahakalMini from "../assets/images/micro-images/mahakal-mini.webp";
import saibabaGarlandMini from "../assets/images/micro-images/shirdi-saibaba-flower-garland-mini.webp";
import vivekanandaOldMini from "../assets/images/micro-images/swami-vivekananda-old-picture-150px.webp";

//Major Topic Images major-topics
import meditationHome from "../assets/images/major-topics/meditation-home.jpg";
import yogaHome from "../assets/images/major-topics/yoga-home.jpg";
import healthHome from "../assets/images/major-topics/health-home.jpg";
import travelHome from "../assets/images/major-topics/travel-home.jpg";
import guruHome from "../assets/images/major-topics/guru-home.jpg";
import festivalHome from "../assets/images/major-topics/festival-home.jpg";

//social media icons
import fb from "../assets/images/social-media/facebook.png";
import insta from "../assets/images/social-media/instagram.png";
import youtube from "../assets/images/social-media/youtube.png";
import twitter from "../assets/images/social-media/twitter.png";
import yoglicaHomeLogo from "../assets/images/yoglica.png";
import yoglica from "../assets/images/yoglica-spiritual-journey-to-enlightenment.webp";

//quotes images
import spiritualQuotes from "../assets/images/quotes/spiritual-quotes.webp";
import bg1mini from "../assets/images/quotes/bhagavad-gita-inspirational-quotes-mini.webp";
import oshoLifemini from "../assets/images/quotes/osho-quotes-on-life-mini.webp";
import oshoDeathmini from "../assets/images/quotes/osho-quotes-on-death-mini.webp";
import bg2Successmini from "../assets/images/quotes/bhagavad-gita-quotes-on-success-mini.webp";
import shirdiSaiBabamini from "../assets/images/quotes/shirdi-sai-baba-quotes-mini.webp";
import sathyaSaiBabaMini from "../assets/images/quotes/satya-sai-baba-quotes-in-english-mini.webp";
import swamiVivekanandaMini from "../assets/images/quotes/success-vivekananda-quotes-life-mini.webp";

import bg1full from "../assets/images/quotes/bhagavad-gita-inspirational-quotes-full.webp";
import oshoLifefull from "../assets/images/quotes/osho-quotes-on-life-full.webp";
import oshoDeathfull from "../assets/images/quotes/osho-quotes-on-death-full.webp";
import bg2Successfull from "../assets/images/quotes/bhagavad-gita-quotes-on-success-full.webp";
import shirdiSaiBabafull from "../assets/images/quotes/shirdi-sai-baba-quotes-full.webp";
import sathyaSaiBabafull from "../assets/images/quotes/satya-sai-baba-quotes-in-english-full.webp";
import swamiVivekanandafull from "../assets/images/quotes/success-vivekananda-quotes-life-full.webp";

import thirdEye from "../assets/images/kundalini-images/third-eye-chakra.webp";
import crown from "../assets/images/kundalini-images/crown-chakra.webp";
import heart from "../assets/images/kundalini-images/heart-chakra.webp";
import root from "../assets/images/kundalini-images/root-chakra.webp";
import sacral from "../assets/images/kundalini-images/sacral-chakra.webp";
import solar from "../assets/images/kundalini-images/solar-chakra.webp";
import throat from "../assets/images/kundalini-images/throat-chakra.webp";


//Full size Image constants
export const fullSizeImages = Object.freeze({
  yoglicaHomeLogo,
  bruntonRamanaFull,
  ramanaChildhoodFull,
  ramanaMaharshiFull,
  shivaFull,
  attachmentFull,
  varnasiFull,
  kundaliniFull,
  kundaliniChakra,
  kedarnathFull,
  pranayamaFull,
  rishikeshFull,
  jagannathFull,
  jagannathTripletFull,
  karmaYogaFull,
  vegetarianDietFull,
  gauMataFull,
  shaniShingnapurFull,
  ekadasiFull,
  buddismFull,
  spiritualFull,
  yoglica,
  radhaKrishnaFull,
  mahaAvatarBabajiFull,
  babajiCaveFull,
  ramcharitmanasFull,
  ashtangaFull,
  shivaArtFull,
  rudrakshaMalaFull,
  mantraJapaWithMalaFull,
  hanumanFull,
  spiritualAwakenFull,
  spiritualSanyasiFull,
  girlDrinkingJuiceFull,
  salvationFull,
  bramacharyaFull,
  mahakalFull,
  banyanTreeFull,
  mahakumbhFull,
  shakthiPeetFull,
  sunrisefull,
  poojafull,
  saibabafull,
  saibabaGarlandFull,
  buddhaBodyFull,
  jesusBlessingDiscipleFull,
  jesusBibleFull,
  adiYogiShivaFull,
  lifePathNumberFull,
  lifePathMatrixFull,
  lifePath1Full,
  frogMoneyEconomyFull,
  shivlingaFull,
  shivaTempleWorshipFull,
  somnathFull,
  santaClausFull,
  bibleBookFull,
  radhaKrishnaImageFull,
  questionWhyHowFull,
  maryJesusFull,
  december25Full,
  lifePath2Full,
  lifePath3Full,
  lifePath4Full,
  lifePath5Full,
  lifePath6Full,
  lifePath7Full,
  lifePath8Full,
  lifePath9Full,
  buddhaPurnimaFull,
  ramanathswamiTempleFull,
  pambanBridgeFull,
  radhaLoveStoryFull,
  scriptureBhagavadGitaFull,
  radhaKrishnaPremKhaniFull,
  cottageLakefull,
  kailashParvathfull,
  kailashTimeTravelfull,
  kailashMountainfull,
  vivekanandaOldfull,
  vivekanandaTemplefull,
  ramMandirFull,
  lordRamFull
});

//Mini size Image constants
export const miniImages = Object.freeze({
  vegetarianMini,
  attachmentMini,
  shivaMini,
  kedarnathMini,
  rishkeshMini,
  varanasiMini,
  hinduGodMini,
  kundaliniMini,
  pranayamaMini,
  ekadasiMini,
  gauMataMini,
  spiritualMini,
  jagannathMini,
  babajiMini,
  ramcharitmanasMini,
  colorFaceMini,
  radheKrishnaMini,
  ashtangaYogaMini,
  rudrakshaMalaMini,
  spiritualAwakeningMini,
  mokshaSalvationMini,
  mahakalMini,
  saibabaMini,
  saibabaGarlandMini,
  spiritualMeditationMini,
  jesusBlessMini,
  adiYogiShivaMini,
  shivalingaMini,
  numerologyMini,
  vivekanandaOldMini,
});

//social media icons
export const socialMediaIcons = Object.freeze({
  fb,
  insta,
  youtube,
  twitter,
});

//quotes media images
export const quotesImages = Object.freeze({
  spiritualQuotes,
  bg1mini,
  bg1full,
  oshoLifemini,
  oshoDeathmini,
  bg2Successmini,
  oshoLifefull,
  oshoDeathfull,
  bg2Successfull,
  shirdiSaiBabafull,
  shirdiSaiBabamini,
  sathyaSaiBabaMini,
  sathyaSaiBabafull,
  swamiVivekanandafull,
  swamiVivekanandaMini,
});

export const kundaliniImages = Object.freeze({
  thirdEye, crown, heart, root, sacral, solar, throat
})

//Relative URL of complete website
export const constURLs = Object.freeze({
  hindu: "/religion/hindu/",
  buddism: "/religion/buddism/",
  yogaAndMeditation: "/yoga-and-meditation/",
  celibacy: "/celibacy/",
  spiritualAwakenUrl: "/spiritual-awakening-practices/",
  ekadeshi: "/religion-and-spirituality/ekadasi-fasting/",
  pranayama: "/yoga-and-meditation/prayanama/",
  kedarnathYatra: "/travel-destination/kedarnath-yatra/",
  shaniShingnapur: "/travel-destination/shani-shingnapur-yatra/",
  varanasi: "/travel-destination/varanasi-yatra/",
  rishikesh: "/travel-destination/rishikesh-yatra/",
  jagannath:
    "/travel-destination/krishna-heart-alive-in-puri-jagannath-temple-miracle/",
  aboutUs: "/about-us/",
  contactUs: "/contact-us/",
  religionAndSpirituality: "/religion-and-spirituality/",
  om: "/religious-beliefs/om/",
  mantraJapa: "/yoga-and-meditation/how-to-practice-mantra-japa/",
  attachment: "/religious-beliefs/non-attachment/",
  consideredDead: "/religious-beliefs/types-of-people-who-are-considered-dead/",
  kundaliniYoga: "/yoga-and-meditation/how-to-awaken-kundalini-chakra/",
  travelDestinations: "/travel-destination/",
  religiousBeliefs: "/religious-beliefs/",
  karmaYoga: "/yoga-and-meditation/karma-yoga/",
  vegetarianDiet: "/religion-and-spirituality/vegetarian-diet/",
  cowWorship: "/religion-and-spirituality/hindu-worship-cow/",
  howToGetSpiritual: "/religion-and-spirituality/how-to-get-spiritual/",
  mahaAvatarBabaji: "/guru/mahavatar-babaji/",
  ramanaMaharshi: "/guru/biography-of-ramana-maharshi/",
  sanyasAshram: "/bhagavad-gita/leaving-family-to-become-a-sanyasi/",
  ashtanga: "/religion-and-spirituality/patanjali-ashtanga-yoga-step/",
  privacy: "/privacy/",
  tnc: "/termsandconditions/",
  disclaimerUrl: "/disclaimer/",
  shivPurana10Things: "/shiva/10-things-of-shiva-purana-useful-in-life/",
  quotes: "/quotes/",
  bg1: "/quotes/top-15-bhagavad-gita-inspirational-quotes/",
  OshoLife: "/quotes/top-15-osho-inspirational-quotes-on-life/",
  OshoDeath: "/quotes/top-15-osho-inspirational-quotes-on-life-and-death/",
  bg2Success: "/quotes/top-7-bhagavad-gita-quotes-on-success/",
  saiQuotes: "/quotes/shirdi-sai-baba/",
  satyaSaiQuotesUrl: "/sathya-sai-baba-quotes-in-english/",
  vivekanandaQuotesUrl: "/success-vivekananda-quotes-life/",
  salvationUrl: "/what-is-salvation-or-moksha/",
  ujjainDarshanUrl: "/travel-destination/ujjain-darshan-tourism/",
  brahmamuhurtaUrl: "/brahma-muhurta-time-benefits/",
  krishnaMgmLessonUrl: "/bhagavad-gita/krishna-life-lessons/",
  saibabaMiracleUrl: "/sai-baba-miracle-stories/",
  meditationBenefitsUrl: "/benefits-of-meditation-daily/",
  bibleFactsUrl: "/amazing-bible-facts-statistics/",
  shivaFactsUrl: "/lord-shiva-facts-hindu-god/",
  lifePathNumberUrl: "/life-path-number-numerology/",
  lifePath_1_Url: "/life-path-number-1-meaning-numerology/",
  lifePath_2_Url: "/life-path-number-2-meaning-numerology/",
  lifePath_3_Url: "/life-path-number-3-meaning-numerology/",
  lifePath_4_Url: "/life-path-number-4-meaning-numerology/",
  lifePath_5_Url: "/life-path-number-5-meaning-numerology/",
  lifePath_6_Url: "/life-path-number-6-meaning-numerology/",
  lifePath_7_Url: "/life-path-number-7-meaning-numerology/",
  lifePath_8_Url: "/life-path-number-8-meaning-numerology/",
  lifePath_9_Url: "/life-path-number-9-meaning-numerology/",
  jyotirlingaUrl: "/jyotirlinga-of-india/",
  mysteryOfChristUrl: "/mystery-of-christ/",
  buddhaPurnima: "/gautam-buddha-purnima-vesak/",
  rameshwaramUrl: "/rameshwaram-jyotirlinga-temple-facts/",
  krishnaPurnaAvatarUrl: "/krishna-purna-avatar-kalas/",
  nidhvanUrl: "/nidhivan-in-vrindavan-mystery/",
  kailashMysteryUrl: "/kailash-mountain-mystery/",
  gitaSummaryUrl: "/bhagavad-gita-summary/",
  radhaAshtamiUrl: "/radha-ashtami/",
  stressReduceSpiritualUrl: "/spiritual-steps-to-reduce-stress/",
  ramJanamBhoomiUrl: "/sree-ram-janmabhoomi-mandir-ayodhya/",
  lifePurposeUrl: "/purpose-of-life-bhagavad-gita/"
});

//Major Topic images
export const homeImages = Object.freeze({
  meditationHome,
  yogaHome,
  healthHome,
  travelHome,
  guruHome,
  festivalHome,
});

export const socialMedia = Object.freeze({
  youtube: "https://www.youtube.com/@yoglica",
  fb: "https://www.facebook.com/yoglicaspirit",
  twitter: "https://twitter.com/yoglicaspirit",
  instagram: "https://www.instagram.com/yoglica",
  pinterest: "https://www.pinterest.ca/yoglica/yoglica/",
  yoglica,
});

export const quotesLatestPostData = Object.freeze({
  //for h1tag and quest
  bg1Quest:
    "Bhagavad Gita - Top 15 Krishna Life Changing Quotes For Inspiration and Success",
  OshoLife: "Osho - Top 15 Osho Life Changing Quotes For Successful Living",
  OshoDeath: "Top 15 Osho Quotes On Life And Death | Mind Handle",
  bg2Success:
    "Top 7 Bhagavad Gita Life Changing Quotes For Motivation and Success",
  shirdisai:
    "32 Best Shirdi Sai Baba Quotes In English Which Are Useful In Life",
  satyaSai:
    "Top 20+ Inspirational Sathya Sai Baba Good morning Quotes In English",
  vivekanandaSuccess:
    "Viral Swami vivekananda quotes on success to turn your dreams now easy",

  bg1QuestDesc:
    "The Shrimad Bhagavad Gita is one of the most sacred texts of the Hindus. Monotheism, Karma Yoga, Jnana Yoga, Bhakti Yoga have been discussed very beautifully in the Bhagavad Gita",
  OshoLifeDesc:
    "Osho's writings and personal anecdotes are full of wisdom and life-affirming insights. Here are some of the quotes from Osho that are the most relevant to today's readers.",
  OshoDeathDesc:
    "Osho was a famous spiritual teacher and a poet. Osho quotes are very popular to inspire people and make them feel good. They are also used as inspirational words to motivate people.",
  bg2SuccessDesc:
    "Bhagavad Gita is considered to be the greatest spiritual book and one of the most inspiring messages in the world. It contains inspiring and uplifting quotes to help readers get the best out of life.",
  shirdisaiDesc:
    "Motivational Quotes - Sai doesnt say make me sit on a silver or gold throne, they say keep faith in your mind and then call your Sai. The importance of a man is not by his clothes but by his conduct.",
  satyaSaiDesc:
    "Sathya sai baba views are very influential. He believed that it is the duty of every individual to ensure that all people have access to the basic necessities of life. We dive deep into spirituality by following his teachings to lead a purposeful life.",
  vivekanandaSuccessDesc:
    "This is the path to success and this is the path by which great spiritual great men are created. Others are just talking machines. Your aim is to be free from every bondage.",
});
