import React from "react";
import '../styles/_footer.scss';
import { NavLink as Link } from "react-router-dom";
import { socialMedia, socialMediaIcons } from '../helper/constants';

import {
	FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton,
	LinkedinIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon,
	PinterestShareButton, PinterestIcon
  } from 'next-share';

export default function footer() {
	const instaIcon = socialMediaIcons.insta;
	const youtubeIcon = socialMediaIcons.youtube;
	const pageURL = window.yoglicapath;
	const pinterest = pageURL.slice(0, -1) + socialMedia.yoglica;
	//const fbpath = "https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.facebook.com%2Fyoglicaspirit&layout=button_count&size=small&width=83&height=20&appId";

	return (
		<footer className="nb-footer">
		<div className="container">
			<div className="row">
				<div className="col-sm-12">
						<div className="about">
							<p>
								
						Get Connected with <span className="yog">Yog</span><span className='lica'>Lica</span> on social networks </p>
							
							<FacebookShareButton
								url={pageURL}
								quote={'Yoglica - Journey to enlightenment'}
								hashtag={'#religion'}>
								<FacebookIcon size={38} round /> 
							</FacebookShareButton>
						{/* <button aria-label="facebook" className="soc-button">
							<a href={fbpath} title="facebook" target="_blank" rel="noopener noreferrer">
								<svg viewBox="0 0 64 64" width="38" height="38"><circle cx="32" cy="32" r="31" fill="#3b5998"></circle><path d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z" fill="white"></path></svg>
							</a>
						</button> */}
						<TwitterShareButton
							url={pageURL}
							title={'Yoglica - Journey to enlightenment'}>
							<TwitterIcon size={38} round />
						</TwitterShareButton>
						<LinkedinShareButton url={pageURL}>
								<LinkedinIcon size={38} round />
						</LinkedinShareButton>
						<WhatsappShareButton
							url={pageURL}
							title={''}
							separator="">
							<WhatsappIcon size={38} round />
						</WhatsappShareButton>
						<EmailShareButton
							url={pageURL}
							subject={'Yoglica - Journey to enlightenment'}
							body="Hi, check out the informative article which I just read.">
							<EmailIcon size={38} round />
						</EmailShareButton>
						<a href={socialMedia.youtube} title="Youtube" target="_blank" rel="noopener noreferrer">
								<img src={youtubeIcon} alt="Youtube Icon" title="Youtube Icon" className="img-fluid youtube-icon" width="40" height="40" />
						</a>
						<a href={socialMedia.instagram} title="Instagram" target="_blank" rel="noopener noreferrer">
							<img src={instaIcon} alt="insta Icon" title="insta Icon" className="img-fluid" width="38" height="38" />
						</a>
						<PinterestShareButton
							url={socialMedia.pinterest}
							media={pinterest}>
							<PinterestIcon size={38} round />
						</PinterestShareButton>
							
					</div>
				</div>

				<div className="col-md-3 col-sm-6 d-none">
					<div className="footer-info-single">
						<h2 className="title">Help Center</h2>
						{/* <ul className="list-unstyled">
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Join Us</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								FAQ's</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Sitemap</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Ask Personal Question</a></li>
						</ul> */}
					</div>
				</div>

				<div className="col-md-3 col-sm-6 d-none">
					<div className="footer-info-single">
						<h2 className="title">Customer information</h2>
						{/* <ul className="list-unstyled">
							<li><a href="/" title="About us"><i className="fa fa-angle-double-right"></i>
								About Us</a></li>
							<li><a href="/" title="What’s New"><i className="fa fa-angle-double-right"></i>
								What’s New</a></li>
							<li><a href="/" title="Subscribe to our latest articles"><i className="fa fa-angle-double-right"></i>
								Subscribe</a></li>
							<li><a href="/" title="Contact Us"><i className="fa fa-angle-double-right"></i>
								Contact Us</a></li>
						</ul> */}
					</div>
				</div>

				<div className="col-md-3 col-sm-6 d-none">
					<div className="footer-info-single">
						<h2 className="title">Navigate</h2>
						<ul className="list-unstyled">
							{/* <li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Science and religion</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Enlightened souls</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Marriage & Family</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Children</a></li>
							<li><a href="/" title=""><i className="fa fa-angle-double-right"></i>
								Videos</a></li> */}

						</ul>
					</div>
				</div>

				<div className="col-md-3 col-sm-6 d-none">
					<div className="footer-info-single">
						{/* <h2 className="title">Donations</h2>
						<p>Sample HTML page with Twitter's Bootstrap. Code example of Easy Sticky Footer using HTML,
							Javascript, jQuery, and CSS. This bootstrap tutorial covers all the major elements of responsive
						</p>
						<button className="btn btn-success btn-sm">Donate</button> */}

					</div>
				</div>
			</div>
			</div>
			<br/>
			<div className="text-center">
				{/* <span className="fb-span">
					<iframe title='fb' src={ fbpath } width="83" height="20" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen={true}></iframe>
				</span> */}
			</div>
		<div className="copyright">
			<div className="container">
				<div className="col-sm-12 text-center">
					Copyright Yoglica.com {(new Date().getFullYear())}. All rights reserved <span className='footerFilter'>|</span>
					<Link to="/privacy/" className="bottom-link" rel="nofollow"> Privacy Policy </Link> <span className='footerFilter'>|</span>
					<Link to="/termsandconditions/" className="bottom-link" rel="nofollow"> Terms of Use </Link><span className='footerFilter'>|</span>
					<Link to="/disclaimer/" className="bottom-link" rel="nofollow"> Disclaimer </Link><span className='footerFilter'>|</span>
					<Link to="/sitemap.xml" target="_blank" className="bottom-link"> Sitemap </Link>
					</div>
					
				</div>
		</div>
	</footer>
  );
}